import { createWebHistory, createRouter } from "vue-router";
//import HelloWorld from "@/components/HelloWorld.vue";
import LogIn from "@/components/LogIn.vue"
import RegisterPage from "@/components/RegisterPage.vue"
import GpucloudPage from "@/components/GpucloudPage.vue"
import MyinstancesPage from "@/components/MyinstancesPage.vue"
import EmailConfirmationPage from "@/components/EmailConfirmationPage.vue"
import EmailConfirmationSuccessPage from "@/components/EmailConfirmationSuccessPage.vue"
import RecoverPasswordPage from "@/components/RecoverPasswordPage.vue"
import RecoverPasswordConfirmationPage from "@/components/RecoverPasswordConfirmationPage.vue"
import RecoverPasswordSuccessPage from "@/components/RecoverPasswordSuccessPage.vue"
import CpucorePage from "@/components/Cpucores.vue"

const routes = [
  {
    path: "/",
    name: "LogIn",
    component: LogIn,
  },
  {
    path: "/register",
    name: "register",
    component: RegisterPage,
  },
  {
    path: "/gpucloud",
    name: "gpucloud",
    component: GpucloudPage,
  },
  {
    path: "/cpucore",
    name: "cpucore",
    component: CpucorePage,
  },

  {
    path: "/myinstances",
    name: "myinstances",
    component: MyinstancesPage,
  },

  {
    path: "/emailconfirmation",
    name: "emailconfirmation",
    component: EmailConfirmationPage,
  },

  {
    path: "/emailconfirmationsuccess",
    name: "emailconfirmationsuccess",
    component: EmailConfirmationSuccessPage,
  },

  {
    path: "/recoverpassword",
    name: "recoverpassword",
    component: RecoverPasswordPage,
  },

  {
    path: "/recoverpasswordconfirmation",
    name: "recoverpasswordconfirmation",
    component: RecoverPasswordConfirmationPage,
  },

  {
    path: "/recoverpasswordsuccess/:token",
    name: "recoverpasswordsuccess",
    component: RecoverPasswordSuccessPage,
  },
];

const router = createRouter({
  history: createWebHistory('/slyd-dashboard-vue'),
  routes
});

export default router;