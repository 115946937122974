<template>
  <div class="right-main-content-wrapper">
    <div class="custom_overlay"></div>
    <div class="blank-white-wrap">
      <div class="togglemenu"><svg width="20" height="18" viewBox="0 0 20 18" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.5625 7.35938H18.8125C18.9156 7.35938 19 7.275 19 7.17188V5.85938C19 5.75625 18.9156 5.67188 18.8125 5.67188H7.5625C7.45937 5.67188 7.375 5.75625 7.375 5.85938V7.17188C7.375 7.275 7.45937 7.35938 7.5625 7.35938ZM7.375 12.1406C7.375 12.2438 7.45937 12.3281 7.5625 12.3281H18.8125C18.9156 12.3281 19 12.2438 19 12.1406V10.8281C19 10.725 18.9156 10.6406 18.8125 10.6406H7.5625C7.45937 10.6406 7.375 10.725 7.375 10.8281V12.1406ZM19.1875 0.75H0.8125C0.709375 0.75 0.625 0.834375 0.625 0.9375V2.25C0.625 2.35313 0.709375 2.4375 0.8125 2.4375H19.1875C19.2906 2.4375 19.375 2.35313 19.375 2.25V0.9375C19.375 0.834375 19.2906 0.75 19.1875 0.75ZM19.1875 15.5625H0.8125C0.709375 15.5625 0.625 15.6469 0.625 15.75V17.0625C0.625 17.1656 0.709375 17.25 0.8125 17.25H19.1875C19.2906 17.25 19.375 17.1656 19.375 17.0625V15.75C19.375 15.6469 19.2906 15.5625 19.1875 15.5625ZM1.3375 12.0492L5.00078 9.16406C5.02545 9.14468 5.04539 9.11994 5.0591 9.09172C5.07281 9.06351 5.07993 9.03254 5.07993 9.00117C5.07993 8.9698 5.07281 8.93884 5.0591 8.91062C5.04539 8.8824 5.02545 8.85767 5.00078 8.83828L1.3375 5.95078C1.20156 5.84297 1 5.93906 1 6.1125V11.8852C0.999987 11.9243 1.01099 11.9627 1.03175 11.9958C1.05251 12.029 1.08219 12.0557 1.1174 12.0728C1.1526 12.0899 1.19192 12.0968 1.23084 12.0926C1.26976 12.0885 1.30672 12.0734 1.3375 12.0492V12.0492Z"
            fill="black" fill-opacity="0.45" />
        </svg></div>

      <!-- <RouterLink class="togglemenu">
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.5625 7.35938H18.8125C18.9156 7.35938 19 7.275 19 7.17188V5.85938C19 5.75625 18.9156 5.67188 18.8125 5.67188H7.5625C7.45937 5.67188 7.375 5.75625 7.375 5.85938V7.17188C7.375 7.275 7.45937 7.35938 7.5625 7.35938ZM7.375 12.1406C7.375 12.2438 7.45937 12.3281 7.5625 12.3281H18.8125C18.9156 12.3281 19 12.2438 19 12.1406V10.8281C19 10.725 18.9156 10.6406 18.8125 10.6406H7.5625C7.45937 10.6406 7.375 10.725 7.375 10.8281V12.1406ZM19.1875 0.75H0.8125C0.709375 0.75 0.625 0.834375 0.625 0.9375V2.25C0.625 2.35313 0.709375 2.4375 0.8125 2.4375H19.1875C19.2906 2.4375 19.375 2.35313 19.375 2.25V0.9375C19.375 0.834375 19.2906 0.75 19.1875 0.75ZM19.1875 15.5625H0.8125C0.709375 15.5625 0.625 15.6469 0.625 15.75V17.0625C0.625 17.1656 0.709375 17.25 0.8125 17.25H19.1875C19.2906 17.25 19.375 17.1656 19.375 17.0625V15.75C19.375 15.6469 19.2906 15.5625 19.1875 15.5625ZM1.3375 12.0492L5.00078 9.16406C5.02545 9.14468 5.04539 9.11994 5.0591 9.09172C5.07281 9.06351 5.07993 9.03254 5.07993 9.00117C5.07993 8.9698 5.07281 8.93884 5.0591 8.91062C5.04539 8.8824 5.02545 8.85767 5.00078 8.83828L1.3375 5.95078C1.20156 5.84297 1 5.93906 1 6.1125V11.8852C0.999987 11.9243 1.01099 11.9627 1.03175 11.9958C1.05251 12.029 1.08219 12.0557 1.1174 12.0728C1.1526 12.0899 1.19192 12.0968 1.23084 12.0926C1.26976 12.0885 1.30672 12.0734 1.3375 12.0492V12.0492Z"
            fill="black" fill-opacity="0.45" />
        </svg>
      </RouterLink> -->
    </div>
    <div class="custom-content-wrap">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  name: "RightPart",
};
</script>
<style scoped>
/*============ Start Right Main Content Wrapper =============*/
.right-main-content-wrapper {
  position: relative;
  left: 264px;
  width: calc(100% - 264px);
  transition: all 0.5s ease;
  background-color: #f5f5f5;
}

.blank-white-wrap {
  padding: 0 10px;
  height: 64px;
  background-color: #ffffff;
}

.togglemenu {
  display: none;
  padding: 21px 12px;
}
.togglemenu svg{
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.togglemenu svg:hover path {
  fill: #5D5FEA;
  fill-opacity: 1;
} 

.togglemenu svg path:active {
  fill: #5D5FEA;
  fill-opacity: 1;
} 

.custom-content-wrap {
  padding: 12px 24px;
  background-color: #ffffff;
  margin: 24px;
  height: calc(100svh - 112px);
  overflow-y: auto;
  width: calc(100% - 48px);
  -ms-overflow-style: none; 
  scrollbar-width: none;
}

.custom-content-wrap::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width:1199.98px) {
  .right-main-content-wrapper {
    left: 0;
    width: 100%;
  }

  .togglemenu {
    display: block;
  }

  .right-main-content-wrapper.blackoverlay .custom_overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.50);
    transition: all 0.3s ease-in-out;
    z-index: 99;
  }

  .blank-white-wrap{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
  }
}
</style>