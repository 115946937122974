<template>
  <div class="inner-custom-container" id="myInstanceList">
    <div class="row" v-if="myGpus != null">
      <div class="col-xl-4 col-sm-6" v-for="item in myGpus" :key="item.ID">
        <div class="gpu-cloud-box">
          <div class="gpu-cloud-head">
            <div class="gpu-head-left">{{ item.gpuType }}</div>
            <div class="gpu-head-right">{{ item.gpuManufacture }}</div>
          </div>
          <div class="gpu-cloud-body">
            <!-- gpu repeat content start -->
            <div class="gpu-top-repeat">
              <div class="gpu-body-left">vRAM</div>
              <div class="gpu-body-right">{{ item.vRam }}Gb</div>
            </div>
            <div class="gpu-top-repeat">
              <div class="gpu-body-left">RAM</div>
              <div class="gpu-body-right">{{ item.ram }}Gb</div>
            </div>
            <div class="gpu-top-repeat">
              <div class="gpu-body-left">VCPU</div>
              <div class="gpu-body-right">{{ item.vCpu }}</div>
            </div>
            <!-- gpu repeat content end -->
            <!-- gpu dropdown content start -->
            <div class="gpu-dorpdown-conent">
              <!-- gpu dropdown box repeat start-->
              <div class="gpu-dorpdown-box">
                <div class="gpu-dropdown-main">
                  <div class="gpu-dropdown-select" style="color: rgba(0, 0, 0, 0.45)">
                    GPUs for rent
                    <div class="gpu-dropdown-change d-inline-block float-end" style="color: rgba(0, 0, 0, 0.88)">
                      <span style="color: rgba(0, 0, 0, 0.88)">{{ item.UserInstances[0].Count }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="gpu-dorpdown-box">
                <div class="gpu-dropdown-main">
                  <div class="gpu-dropdown-select">
                    <div class="gpu-dropdown-change">
                      1 {{ item.UserInstances[0].Plan === 2.48 ? "hr" : item.UserInstances[0].Plan === 54.24 ? "day" : "month" }}
                      <span style="color: rgba(0, 0, 0, 0.88)">${{ item.UserInstances[0].Plan === 2.48 ? 2.48 : item.UserInstances[0].Plan === 54.24 ? 2.26 : 2.16 }}/hr</span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- gpu dropdown box repeat end-->
              <div class="my-instances-box">
                <span>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Icon / ApiFilled">
                      <path id="Vector"
                        d="M21.5084 3.48788L20.5147 2.49413C20.4772 2.45663 20.4303 2.44022 20.3811 2.44022C20.3319 2.44022 20.285 2.45897 20.2475 2.49413L18.4639 4.27772C17.6885 3.75238 16.7731 3.47229 15.8366 3.47382C14.6366 3.47382 13.4366 3.93085 12.5201 4.84726L10.1319 7.23554C10.097 7.27078 10.0774 7.31837 10.0774 7.36796C10.0774 7.41755 10.097 7.46514 10.1319 7.50038L16.4998 13.8683C16.5373 13.9058 16.5842 13.9223 16.6334 13.9223C16.6803 13.9223 16.7295 13.9035 16.767 13.8683L19.1553 11.4801C20.7701 9.86288 20.96 7.3621 19.7248 5.53866L21.5084 3.75507C21.5811 3.68007 21.5811 3.56054 21.5084 3.48788V3.48788ZM13.5678 12.8137C13.5326 12.7788 13.485 12.7592 13.4354 12.7592C13.3858 12.7592 13.3382 12.7788 13.303 12.8137L11.742 14.3746L9.62562 12.2582L11.1889 10.6949C11.2616 10.6223 11.2616 10.5027 11.1889 10.4301L10.3358 9.57694C10.3005 9.54205 10.2529 9.52247 10.2034 9.52247C10.1538 9.52247 10.1062 9.54205 10.0709 9.57694L8.50765 11.1402L7.49984 10.1324C7.48234 10.1149 7.46149 10.1011 7.43853 10.0918C7.41558 10.0826 7.39099 10.078 7.36624 10.0785C7.31937 10.0785 7.27015 10.0973 7.23265 10.1324L4.84671 12.5207C3.23187 14.1355 3.04202 16.6387 4.27718 18.4621L2.49359 20.2457C2.45869 20.2809 2.43912 20.3285 2.43912 20.3781C2.43912 20.4277 2.45869 20.4753 2.49359 20.5105L3.48734 21.5043C3.52484 21.5418 3.57171 21.5582 3.62093 21.5582C3.67015 21.5582 3.71702 21.5394 3.75452 21.5043L5.53812 19.7207C6.32796 20.2574 7.24671 20.5246 8.16546 20.5246C9.36546 20.5246 10.5655 20.0676 11.4819 19.1512L13.8701 16.7629C13.9428 16.6902 13.9428 16.5707 13.8701 16.498L12.8623 15.4902L14.4256 13.9269C14.4983 13.8543 14.4983 13.7348 14.4256 13.6621L13.5678 12.8137V12.8137Z"
                        fill="#52C41A" />
                    </g>
                  </svg>
                </span>
                <div class="my-instances-text">
                  <h4>Your <strong>GPU</strong> live</h4>
                  <p>
                    Available for use until
                    <strong>{{ getFutureDateTime(item.UserInstances[0].PurchasedAt, item.UserInstances[0].Plan === 2.48 ? 1 : item.UserInstances[0].Plan === 54.24 ? 24 : 720).date }}</strong> <br><span :data-date="getFutureDateTime(item.UserInstances[0].PurchasedAt, item.UserInstances[0].Plan === 2.48 ? 1 : item.UserInstances[0].Plan === 54.24 ? 24 : 720).expireAt" class="timer"></span>
                  </p>
                </div>
              </div>
              <RouterLink to="" data-bs-toggle="modal" data-bs-target="#viewdetails1" class="btn-default-violet full-btn">View details</RouterLink>
            </div>
            <!-- gpu dropdown content end -->
          </div>
        </div>
      </div>


      <!-- My Cpus -->
      <div class="col-xl-4 col-sm-6" v-for="item in myCpus" :key="item.ID">
        <div class="gpu-cloud-box">
          <div class="gpu-cloud-head">
            <div class="gpu-head-left">{{ item.cpuType }}</div>
            <div class="gpu-head-right">{{ item.cpuManufacture }}</div>
          </div>
          <div class="gpu-cloud-body">
            <!-- cpu repeat content start -->
            <div class="gpu-top-repeat">
              <div class="gpu-body-left">vCPU</div>
              <div class="gpu-body-right">{{ item.vCpu }}Gb</div>
            </div>
            <div class="gpu-top-repeat">
              <div class="gpu-body-left">RAM</div>
              <div class="gpu-body-right">{{ item.ram }}Mb</div>
            </div>
            <div class="gpu-top-repeat">
              <div class="gpu-body-left">Disk</div>
              <div class="gpu-body-right">{{ item.disk }}</div>
            </div>
            <!-- cpu repeat content end -->
            <!-- cpu dropdown content start -->
            <div class="gpu-dorpdown-conent">
              <!-- cpu dropdown box repeat start-->
              <div class="gpu-dorpdown-box">
                <div class="gpu-dropdown-main">
                  <div class="gpu-dropdown-select" style="color: rgba(0, 0, 0, 0.45)">
                    CPUs for rent
                    <div class="gpu-dropdown-change d-inline-block float-end" style="color: rgba(0, 0, 0, 0.88)">
                      <span style="color: rgba(0, 0, 0, 0.88)">{{ item.UserInstances[0].Count }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="gpu-dorpdown-box">
                <div class="gpu-dropdown-main">
                  <div class="gpu-dropdown-select">
                    <div class="gpu-dropdown-change">
                      1 {{ item.UserInstances[0].Plan === 2.48 ? "hr" : item.UserInstances[0].Plan === 54.24 ? "day" : "month" }}
                      <span style="color: rgba(0, 0, 0, 0.88)">${{ item.UserInstances[0].Plan === 2.48 ? 2.48 : item.UserInstances[0].Plan === 54.24 ? 2.26 : 2.16 }}/hr</span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- cpu dropdown box repeat end-->
              <div class="my-instances-box">
                <span>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Icon / ApiFilled">
                      <path id="Vector"
                        d="M21.5084 3.48788L20.5147 2.49413C20.4772 2.45663 20.4303 2.44022 20.3811 2.44022C20.3319 2.44022 20.285 2.45897 20.2475 2.49413L18.4639 4.27772C17.6885 3.75238 16.7731 3.47229 15.8366 3.47382C14.6366 3.47382 13.4366 3.93085 12.5201 4.84726L10.1319 7.23554C10.097 7.27078 10.0774 7.31837 10.0774 7.36796C10.0774 7.41755 10.097 7.46514 10.1319 7.50038L16.4998 13.8683C16.5373 13.9058 16.5842 13.9223 16.6334 13.9223C16.6803 13.9223 16.7295 13.9035 16.767 13.8683L19.1553 11.4801C20.7701 9.86288 20.96 7.3621 19.7248 5.53866L21.5084 3.75507C21.5811 3.68007 21.5811 3.56054 21.5084 3.48788V3.48788ZM13.5678 12.8137C13.5326 12.7788 13.485 12.7592 13.4354 12.7592C13.3858 12.7592 13.3382 12.7788 13.303 12.8137L11.742 14.3746L9.62562 12.2582L11.1889 10.6949C11.2616 10.6223 11.2616 10.5027 11.1889 10.4301L10.3358 9.57694C10.3005 9.54205 10.2529 9.52247 10.2034 9.52247C10.1538 9.52247 10.1062 9.54205 10.0709 9.57694L8.50765 11.1402L7.49984 10.1324C7.48234 10.1149 7.46149 10.1011 7.43853 10.0918C7.41558 10.0826 7.39099 10.078 7.36624 10.0785C7.31937 10.0785 7.27015 10.0973 7.23265 10.1324L4.84671 12.5207C3.23187 14.1355 3.04202 16.6387 4.27718 18.4621L2.49359 20.2457C2.45869 20.2809 2.43912 20.3285 2.43912 20.3781C2.43912 20.4277 2.45869 20.4753 2.49359 20.5105L3.48734 21.5043C3.52484 21.5418 3.57171 21.5582 3.62093 21.5582C3.67015 21.5582 3.71702 21.5394 3.75452 21.5043L5.53812 19.7207C6.32796 20.2574 7.24671 20.5246 8.16546 20.5246C9.36546 20.5246 10.5655 20.0676 11.4819 19.1512L13.8701 16.7629C13.9428 16.6902 13.9428 16.5707 13.8701 16.498L12.8623 15.4902L14.4256 13.9269C14.4983 13.8543 14.4983 13.7348 14.4256 13.6621L13.5678 12.8137V12.8137Z"
                        fill="#52C41A" />
                    </g>
                  </svg>
                </span>
                <div class="my-instances-text">
                  <h4>Your <strong>CPU</strong> live</h4>
                  <p>
                    Available for use until
                    <strong>{{ getFutureDateTime(item.UserInstances[0].PurchasedAt, item.UserInstances[0].Plan === 2.48 ? 1 : item.UserInstances[0].Plan === 54.24 ? 24 : 720).date }}</strong> <br><span :data-date="getFutureDateTime(item.UserInstances[0].PurchasedAt, item.UserInstances[0].Plan === 2.48 ? 1 : item.UserInstances[0].Plan === 54.24 ? 24 : 720).expireAt" class="timer"></span>
                  </p>
                </div>
              </div>
              <RouterLink to="" data-bs-toggle="modal" data-bs-target="#viewdetails1" class="btn-default-violet full-btn">View details</RouterLink>
            </div>
            <!-- cpu dropdown content end -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div class="modal fade myinstances-view" id="viewdetails1" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <h5>Connect to your instance</h5>
          <p>Please use the following command to connect to your instance.</p>
          <div class="clipboard">
            <label>SSH command</label>
            <input class="copy-input" value="ssh mladmin@142.179.7.179" readonly />
            <button class="copy-btn">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Icon / CopyOutlined">
                  <path id="Vector"
                    d="M13 1H4.625C4.55625 1 4.5 1.05625 4.5 1.125V2C4.5 2.06875 4.55625 2.125 4.625 2.125H12.375V12.875C12.375 12.9438 12.4312 13 12.5 13H13.375C13.4438 13 13.5 12.9438 13.5 12.875V1.5C13.5 1.22344 13.2766 1 13 1ZM11 3H3C2.72344 3 2.5 3.22344 2.5 3.5V11.7922C2.5 11.925 2.55312 12.0516 2.64687 12.1453L5.35469 14.8531C5.38906 14.8875 5.42813 14.9156 5.47031 14.9391V14.9688H5.53594C5.59062 14.9891 5.64844 15 5.70781 15H11C11.2766 15 11.5 14.7766 11.5 14.5V3.5C11.5 3.22344 11.2766 3 11 3ZM5.46875 13.3781L4.12344 12.0312H5.46875V13.3781ZM10.375 13.875H6.46875V11.6562C6.46875 11.3109 6.18906 11.0312 5.84375 11.0312H3.625V4.125H10.375V13.875Z"
                    fill="black" fill-opacity="0.88" />
                </g>
              </svg>
            </button>
          </div>
          <div class="clipboard">
            <label>Password</label>
            <input class="copy-input" value="#26315NZkG" readonly />
            <button class="copy-btn" v-on:click="copy">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Icon / CopyOutlined">
                  <path id="Vector"
                    d="M13 1H4.625C4.55625 1 4.5 1.05625 4.5 1.125V2C4.5 2.06875 4.55625 2.125 4.625 2.125H12.375V12.875C12.375 12.9438 12.4312 13 12.5 13H13.375C13.4438 13 13.5 12.9438 13.5 12.875V1.5C13.5 1.22344 13.2766 1 13 1ZM11 3H3C2.72344 3 2.5 3.22344 2.5 3.5V11.7922C2.5 11.925 2.55312 12.0516 2.64687 12.1453L5.35469 14.8531C5.38906 14.8875 5.42813 14.9156 5.47031 14.9391V14.9688H5.53594C5.59062 14.9891 5.64844 15 5.70781 15H11C11.2766 15 11.5 14.7766 11.5 14.5V3.5C11.5 3.22344 11.2766 3 11 3ZM5.46875 13.3781L4.12344 12.0312H5.46875V13.3781ZM10.375 13.875H6.46875V11.6562C6.46875 11.3109 6.18906 11.0312 5.84375 11.0312H3.625V4.125H10.375V13.875Z"
                    fill="black" fill-opacity="0.88" />
                </g>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { eventBus } from '@/main'
import moment from 'moment';
export default {
  name: "MyinstancesPage",
  data () {
    return {
      myGpus: [],
      myCpus: []
    }
  },
  methods: {
    fetchInstances() {
      const idToken = localStorage.getItem('id_token')
      return fetch('https://slyd-fe5cef1c6ee5.herokuapp.com/api/instances/myinstances', {
        headers: {
          "Authorization": 'Bearer ' + idToken
        }
      })
      .then((response) => response.json())
      .then((json) => {
        this.myGpus = json?.data?.gpu;
        this.myCpus = json?.data?.cpu;
      })
      .catch((error) => {
        console.log('Error Fetching User Data:', error);
      });
    },
    getFutureDateTime(timestampStr, hoursToAdd) {

      const timestamp = moment(timestampStr, moment.ISO_8601);

      const futureTimestamp = timestamp.add(hoursToAdd, 'hours')
      const expireAt = futureTimestamp.format("YYYY-MM-DDTHH:mm:ss");
      const date = futureTimestamp.format('MMM DD')

      return { expireAt, date };
    }
  },
  mounted() {
    eventBus.emit('current-route', "myinstances")
    this.fetchInstances().then(() => {

      console.log(new Date());
      $('#myInstanceList').find('.col-xl-4').each(function(){
        var futureTime = new Date($(this).find('.timer').attr('data-date'));
        var timeLeft = Math.floor((futureTime - new Date().getTime()) / 1000);
        var timerId = 'timer_' + $(this).index();
        $(this).find('.timer').append('<span id="' + timerId + '"></span>');
        startCountdown(timerId, timeLeft);
      });
  
      function startCountdown(timerId, durationInSeconds) {
        let timerElement = $('#' + timerId);
        let timer = durationInSeconds;
        let hours, minutes, seconds;

        function updateTimer() {
          hours = Math.floor(timer / 3600);
          minutes = Math.floor((timer % 3600) / 60);
          seconds = timer % 60;

          timerElement.text(`${hours}:${minutes}:${seconds}`);
        }

        function countdown() {
          if (timer <= 0) {
            clearInterval(intervalId);
            timerElement.text("Time's up!");
          } else {
            updateTimer();
            timer--;
          }
          if (timer == 0) {
            window.location.reload()
          }
        }

        // Initial display
        updateTimer();

        // Start the countdown
        let intervalId = setInterval(countdown, 1000);
      }
  
      $('.copy-btn').click(function(){
        $(this).parent('.clipboard').find('input.copy-input').select();      
        document.execCommand("copy");
      });
    })
  }
};
</script>

<style scoped>
.gpu-cloud-wrap {
  padding: 24px;
  background-color: #ffffff;
  margin: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.gpu-cloud-wrap {
  padding-top: 12px;
  padding-bottom: 12px;
}

.gpu-cloud-box {
  margin: 12px 0;
  border-radius: var(--card-border-radius-lg, 8px);
  border: 1px solid var(--card-color-border-secondary, rgba(0, 0, 0, 0.06));
  background: var(--card-color-bg-container, #fff);
}

.gpu-cloud-head {
  padding: 16px 24px;
  border-bottom: 1px solid var(--card-color-border-secondary, rgba(0, 0, 0, 0.06));
  background: var(--card-color-fill-alter, rgba(0, 0, 0, 0.02));
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.gpu-head-left {
  color: var(--card-color-text, rgba(0, 0, 0, 0.88));
  font-weight: 600;
  line-height: 22px;
}

.gpu-head-right {
  color: var(--colorTextDescription, rgba(0, 0, 0, 0.45));
  font-size: 12px;
  line-height: 20px;
}

.gpu-cloud-body {
  padding: 24px 24px;
}

.gpu-top-repeat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.gpu-top-repeat+.gpu-top-repeat {
  margin-top: 12px;
}

.gpu-body-left {
  color: var(--colorTextDescription, rgba(0, 0, 0, 0.45));
  line-height: 22px;
}

.gpu-body-right {
  color: var(--colorTextDescription, rgba(0, 0, 0, 0.88));
  line-height: 22px;
}

.gpu-dorpdown-conent {
  margin-top: 24px;
}

.gpu-dorpdown-box+.gpu-dorpdown-box {
  margin-top: 12px;
}

.gpu-dropdown-main {
  display: flex;
  min-height: 40px;
  padding: 0 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--button-border-radius, 6px);
  border: 1px solid var(--button-color-border, rgba(0, 0, 0, 0.15));
  background: var(--button-color-bg-container, #fff);
  border-radius: 6px;
  position: relative;
  cursor: pointer;
}

.gpu-dorpdown-box+.gpu-dorpdown-box {
  margin-top: 12px;
}

.gpu-dropdown-select {
  width: 100%;
}

.gpu-dropdown-select svg {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.5s;
}

.gpu-dropdown-select.arw-roated svg {
  transform: translateY(-50%) rotate(180deg);
  transition: 0.5s;
}

.gpu-dropdown-change {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--colorTextDescription, rgba(0, 0, 0, 0.45));
  line-height: 22px;
}

.gpu-dropdown-change span:first-child {
  color: var(--colorTextDescription, rgba(0, 0, 0, 0.45));
}

.gpu-dropdown-change span {
  display: inline-block;
  color: var(--colorTextDescription, rgba(0, 0, 0, 0.88));
}

.gpu-dropdown-slide {
  position: absolute;
  width: 100%;
  right: 0;
  top: 45px;
  border-radius: var(--dropdown-border-radius-lg, 8px);
  background: var(--dropdown-color-bg-elevated, #fff);
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08);
  display: none;
  z-index: 9;
  border-bottom: 5px #5d5fea solid;
}

.gpu-dropdown-slide ul {
  padding: 4px;
  margin: 0;
}

.gpu-dropdown-slide ul li {
  display: flex;
  padding: 6px 12px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: var(--button-color-text, rgba(0, 0, 0, 0.88));
  gap: 8px;
  border-radius: 4px;
}

.gpu-dropdown-slide ul li:hover {
  background: #efeffd;
  color: #5d5fea;
}

.my-instances-box {
  border-radius: var(--alert-border-radius-lg, 8px);
  border: 1px solid var(--alert-color-success-border, #b7eb8f);
  background: var(--alert-color-success-bg, #f6ffed);
  padding: 20px 24px;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  margin-top: 24px;
  color: var(--alert-color-text, rgba(0, 0, 0, 0.88));
}

.my-instances-box h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 8px;
}

.my-instances-box h4 strong,
.my-instances-box p strong,
.timer {
  font-weight: 600;
}

.timer {
  display: inline-block;
  /* min-width: 60px; */
}


.my-instances-box p {
  line-height: 22px;
  margin: 0;
}

.gpu-dorpdown-conent .btn-default-violet {
  margin-top: 24px;
}

.gpu-dorpdown-conent .btn-default-violet {
  margin-top: 24px;
}

.modal.myinstances-view {
  width: calc(100% - 264px);
  left: auto;
  right: 0;
}

.modal.myinstances-view .modal-content {
  max-width: 400px;
  padding: 20px 24px;
  margin: 0 auto;
}

.modal.myinstances-view .modal-header {
  padding: 0;
  border: none;
}

.modal.myinstances-view .modal-body {
  padding: 0;
  color: var(--notification-color-text, rgba(0, 0, 0, 0.88));
}

.modal.myinstances-view button.btn-close {
  padding: 0;
  position: absolute;
  right: 30px;
  top: 31px;
  z-index: 9;
}

.modal.myinstances-view .modal-body h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
  padding-right: 15px;
}

.modal.myinstances-view .modal-body p {
  line-height: 22px;
  margin-bottom: 24px;
}

.clipboard {
  position: relative;
}

.clipboard+.clipboard {
  margin-top: 12px;
}

.clipboard label {
  line-height: 22px;
  margin-bottom: 8px;
  widows: 100%;
}

.copy-input {
  color: var(--input-color-text, rgba(0, 0, 0, 0.88));
  font-size: 16px;
  line-height: 24px;
  padding: 7px 12px;
  border-radius: 8px !important;
  border: 1px solid var(--input-color-border, rgba(0, 0, 0, 0.15));
  width: 100%;
  min-height: 40px;
  overflow: hidden;
}

.copy-input:focus {
  outline: none;
}

.copy-btn {
    width: 40px;
    padding: 6px 9px;
    margin-left: -41px;
    border: none;
    border-left: 1px solid var(--input-color-border, rgba(0, 0, 0, 0.15)) !important;
    background: var(--input-color-fill-alter, rgba(0, 0, 0, 0.02));
    transition: all 0.4s;
    min-height: 38px;
    top: 31px;
    cursor: pointer;
    position: absolute;
    border-radius: 0 8px 8px 0;
}

.copy-btn:hover {
  background: var(--border-color, #EEE);
}

.copy-btn:focus {
  outline: none;
}

.copied {
  font-family: "Montserrat", sans-serif;
  width: 100px;
  opacity: 0;
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: auto;
  color: #000;
  padding: 15px 15px;
  background-color: #fff;
  border-radius: 5px;
  transition: 0.4s opacity;
}

.modal.myinstances-view .modal-body .input-group {
  margin-bottom: 12px;
}

.copy-input ::placeholder {
  color: var(--input-color-text, rgba(0, 0, 0, 0.88));
}

.copy-input ::-ms-input-placeholder {
  color: var(--input-color-text, rgba(0, 0, 0, 0.88));
}

@media only screen and (max-width: 1199.98px) {
  .modal.myinstances-view {
    width: 100%;
    padding: 0 15px;
  }
}

@media only screen and (max-width: 599.98px) {
  .custom-content-wrap .inner-custom-container .row>* {
    width: 100%;
  }

  .copy-input {
    font-size: 14px;
    line-height: 22px;
  }
}
</style>