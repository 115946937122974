<template>
    <div class="others-content-wrap">
        <div class="others-custom-container">
            <h2>Recover password</h2>
            <p>Enter the email address you used to register and we’ll send you the instruction.</p>
            <div class="recover-email">
                <form @submit.prevent="handleFormSubmit">
                    <div class="custom-form">
                        <label>Email</label>
                        <input class="form-control" :class="{'error': showEmailError}" v-model="credentials.email" placeholder="Email" />
                        <p class="error_msg" v-if="showEmailError">{{ emailError }}</p>
                        <input type="submit" v-if="!isLoading" class="btn-default-violet full-btn" value="Reset password">
                        <div v-if="isLoading" class="btn-default-violet loading full-btn">
                            <VueSpinner size="20" color="white" />
                        </div>
                    </div>
                </form>
                <div class="back-login">
                    Back to <RouterLink to="/">Log in</RouterLink>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import { RouterLink } from 'vue-router';
import { VueSpinner } from 'vue3-spinners';

export default {
    name: "recoverPassword",
    components: { RouterLink, VueSpinner },
    data() {
        return {
            credentials: {
                email: ''
            },
            emailError: 'Please enter a valid email address.',
            isLoading: false,
            showEmailError: false,
        }
    },
    computed: {
        isValidEmail() {
        return /^[^@]+@\w+(\.\w+)+\w$/.test(this.credentials.email);
        },
    },
    methods: {
        handleFormSubmit() {
            if (!this.credentials.email) {
                this.emailError = "Please enter your email."
                this.showEmailError = true;
                setTimeout(() => {
                this.emailError = "Please enter a valid email address."
                this.showEmailError = false;
                }, 2500);
                return;
            } else if (!this.isValidEmail) {
                this.showEmailError = true;
                setTimeout(() => {
                this.showEmailError = false;
                }, 2500);
                return;
            }
            const credentials = {
                email:this.credentials.email
            }

            auth.forgotPassword(this, credentials, '/recoverpasswordconfirmation')
            // If you are using Vue Router, you can navigate to a different route
            // this.$router.push('/recoverpasswordconfirmation');
        }
    },
    mounted() {
        const encodedEmail = this.$route.query.email;
        this.credentials.email = decodeURIComponent(encodedEmail);
    },
}
</script>

<style scoped>
.others-content-wrap {
    min-height: 100%;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    padding: 12px 0;
}

.others-custom-container {
    max-width: 460px;
    margin: 0 auto;
    width: 100%;
    text-align: center;
}

.others-content-wrap h2 {
    color: var(--result-color-text-heading, rgba(0, 0, 0, 0.88));
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 8px;
}

.others-content-wrap p {
    color: var(--result-color-text-description, rgba(0, 0, 0, 0.45));
    line-height: 22px;
}

.recover-email {
    margin-top: 24px;
}

.back-login {
    color: var(--colorText, rgba(0, 0, 0, 0.88));
    margin-bottom: 0;
    margin-top: -4px;
}

.back-login a {
    padding: 0 0 0 8px;
    color: #5D5FEA;
}

.back-login a:hover {
    color: #7475E8;
}

.back-login a:active {
    color: #4446E8;
}

.others-content-wrap .btn-default-violet {
    margin-top: 24px;
}
</style>