<template>
    <LeftPart />
    <RightPart />
</template>

<script>
import $ from 'jquery'
import LeftPart from "./components/LeftPart.vue";
import RightPart from "./components/RightPart.vue";

export default {
    name: 'App',
    components: {
        LeftPart,
        RightPart
    },
    mounted() {
        $(document).ready(function () {
            // ///////////////////////////// sidebar js start
            $(".togglemenu > svg").click(function (e) {
                e.preventDefault();
                $(".sidebar").addClass("active");
                $(".right-main-content-wrapper").addClass("blackoverlay");
            });
            $(".closemenu").click(function (e) {
                e.preventDefault();
                $(".sidebar").removeClass("active");
                $(".right-main-content-wrapper").removeClass("blackoverlay");
            });

            $(".nav-bar ul li a").click(function (e){
                e.preventDefault();
                $(".sidebar").removeClass("active");
                $(".right-main-content-wrapper").removeClass("blackoverlay");
                $(this).toggleClass('activelink');
                $(this).parents('li').siblings().find('> a').removeClass('activelink') ;
                $(this).parents('li').siblings().find('.arw-nav').removeClass('actv');
                $(this).parents('li').siblings().find('ul').stop(true, true).slideUp();
            });

            $(".nav-bar ul li a span").click(function (){
                $(".sidebar").addClass("active"); 
            });
            // ///////////////////////////// sidebar js end

            // ///////////////////////////// nav link arrow start
            $('.nav-bar ul > li > ul').parent().prepend('<div class="arw-nav"><svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.3594 0H8.4805C8.42073 0 8.36448 0.0292969 8.32932 0.0773438L5.00003 4.66641L1.67073 0.0773438C1.63557 0.0292969 1.57932 0 1.51956 0H0.640651C0.564479 0 0.519948 0.0867189 0.564479 0.148828L4.69651 5.84531C4.84651 6.05156 5.15354 6.05156 5.30237 5.84531L9.4344 0.148828C9.48011 0.0867189 9.43557 0 9.3594 0V0Z" fill="white" fill-opacity="0.65"/></svg></div>');
            function subMenu(){
                // $(this).parent('li').toggleClass('activee');
                $(this).parent('li').find('> ul').stop(true, true).slideToggle();
                $(this).parents('li').siblings().find('ul').stop(true, true).slideUp();
                $(this).parent().find('.arw-nav').toggleClass('actv');
            }
            $('.nav-bar ul > li > .arw-nav').on('click',subMenu);
            function activeLinkhide(){
                $(this).parent('li').siblings().find('> a').removeClass('activelink');
            }
            $('.nav-bar ul > li > .arw-nav').on('click',activeLinkhide);
            // ///////////////////////////// nav link arrow end


            $('.custom_overlay').click(function(){
                $(".sidebar").removeClass("active");
                $(".right-main-content-wrapper").removeClass("blackoverlay");
            });


        });

    }
}


</script>

<style>
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
    font-family: sans-serif;
    /* 1 */

    -ms-text-size-adjust: 100%;
    /* 2 */

    -webkit-text-size-adjust: 100%;
    /* 2 */
}

/**
 * Remove default margin.
 */

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
    display: block;
}

/**
 * 1. Correct `inline-block` display not defined in IE 8/9.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */

audio,
canvas,
progress,
video {
    display: inline-block;
    /* 1 */

    vertical-align: baseline;
    /* 2 */
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */

[hidden],
template {
    display: none;
}

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */

a {
    background-color: transparent;
}

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */

a:active,
a:hover {
    outline: 0;
}

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */

abbr[title] {
    border-bottom: 1px dotted;
}

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */

b,
strong {
    font-weight: bold;
}

/**
 * Address styling not present in Safari and Chrome.
 */

dfn {
    font-style: italic;
}

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

/**
 * Address styling not present in IE 8/9.
 */

mark {
    background: #ff0;
    color: #000;
}

/**
 * Address inconsistent and variable font size in all browsers.
 */

small {
    font-size: 80%;
}

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

/* Embedded content
   ========================================================================== */
/**
 * Remove border when inside `a` element in IE 8/9/10.
 */

img {
    border: 0;
}

/**
 * Correct overflow not hidden in IE 9/10/11.
 */

svg:not(:root) {
    overflow: hidden;
}

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */

figure {
    margin: 1em 40px;
}

/**
 * Address differences between Firefox and other browsers.
 */

hr {
    box-sizing: content-box;
    height: 0;
}

/**
 * Contain overflow in all browsers.
 */

pre {
    overflow: auto;
}

/**
 * Address odd `em`-unit font size rendering in all browsers.
 */

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *    Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 */

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    /* 1 */

    font: inherit;
    /* 2 */

    margin: 0;
    /* 3 */
}

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */

button {
    overflow: visible;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */

button,
select {
    text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */

button,
html input[type="button"],
/* 1 */

input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    /* 2 */

    cursor: pointer;
    /* 3 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
    cursor: default;
}

/**
 * Remove inner padding and border in Firefox 4+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

input {
    line-height: normal;
}

/**
 * It's recommended that you don't attempt to style these elements.
 * Firefox's implementation doesn't respect box-sizing, padding, or width.
 *
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 */

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    /* 1 */

    padding: 0;
    /* 2 */
}

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */

input[type="search"] {
    -webkit-appearance: textfield;
    /* 1 */

    box-sizing: content-box;
    /* 2 */
}

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 */

legend {
    border: 0;
    /* 1 */

    padding: 0;
    /* 2 */
}

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */

textarea {
    overflow: auto;
}

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */

optgroup {
    font-weight: bold;
}

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

td,
th {
    padding: 0;
}

::-moz-selection {
    background-color: #007d9d;
    color: #fff;
    text-shadow: none;
}

::selection {
    background-color: #007d9d;
    color: #fff;
    text-shadow: none;
}

input::-moz-placeholder,
textarea::-moz-placeholder,
select::-moz-placeholder {
    color: inherit;
    opacity: 0.7;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder,
select:-ms-input-placeholder {
    color: inherit;
    opacity: 0.7;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
select::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.7;
}

body {
    margin: 0;
    padding: 0;
    font-size: 14px !important;
    font-family: 'SF Pro Text' !important;
    background: #f5f5f5;
}

html,body{
    overflow: hidden; 
}

.container:after,
.container:before {
    content: " ";
    display: table;
}

.container:after {
    clear: both;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
figure {
    margin: 0;
    padding: 0;
}

img {
    margin: 0;
    padding: 0;
    border: none;
    max-width: 100%;
    height: auto !important;
    display: block;
}

a,
button,
input {
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

a {
    text-decoration: none !important;
}

/**************global css end*******/

@font-face {
    font-family: 'SF Pro Text';
    src: url('./assets/fonts/SFProText-Regular.eot');
    src: url('./assets/fonts/SFProText-Regular.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/SFProText-Regular.woff2') format('woff2'),
        url('./assets/fonts/SFProText-Regular.woff') format('woff'),
        url('./assets/fonts/SFProText-Regular.ttf') format('truetype'),
        url('./assets/fonts/SFProText-Regular.svg#SFProText-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

::selection {
  background: #5D5FEA;
}

.container {
    position: relative;
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 15px;
    width: 100% !important;
}

.custom-form {
    margin-bottom: 12px;
    position: relative;
    text-align: left;
}

.custom-form label {
    line-height: 22px;
    margin-bottom: 6px;
}

.custom-form .form-control {
    border-radius: 6px;
    padding: 8px 12px;
    border: 1px rgba(0, 0, 0, 0.15) solid;
    color: var(--input-color-text, rgba(0, 0, 0, 0.88));
    font-size: 16px;
    line-height: 24px;
}

.custom-form .form-control.password {
    padding-right: 35px;
}

.custom-form .form-control::placeholder {
    color: rgba(0, 0, 0, 0.25);
    opacity: 1;
}

.custom-form .form-control::-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.25);
}

.custom-form .form-control:focus {
    color: rgba(0, 0, 0, 0.88) !important;
    box-shadow: none;
}

.custom-form .form-control:hover,
.custom-form .form-control:focus,
.custom-form .form-control:focus-within {
    border-color: #5D5FEA;
}

.custom-form .form-control.error{
    border-color: #FF4D4F;
}

.error_msg{
    color:#FF4D4F !important;
    font-weight:400;
    size:14px;
    line-height:22px;
    margin:2px 0 0;
}

.input-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: rgba(0, 0, 0, 0.25);
}

.input-icon img.visible_eye{
    display: none;
}
.input-icon.active img.visible_eye{
    display: block;
}
.input-icon.active img.invisible_eye{
    display: none;
}

.btn-default-violet {
    font-size: 16px;
    line-height: 24px;
    padding: 8px 16px 9px;
    border-radius: 8px;
    background: #5D5FEA;
    color: #ffffff !important;
    text-align: center;
    border: none;
}

.btn-default-violet:hover {
    background: #7475E8;
}

.btn-default-violet:active {
    background: #4446E8;
}

.loading {
    background: #7475E8;
    cursor: not-allowed;
}

.full-btn {
    width: 100%;
    display: block;
}

.arw-nav {
    width: 96%;
    height: 48px;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    z-index: 999;
    cursor: pointer;
    margin: 0 auto;
}

.arw-nav > svg {
    position: absolute;
    top: 0;
    right: 17px;
    bottom: 0;
    margin: auto;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.arw-nav.actv > svg {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}
.arw-nav.actv > svg path,
.arw-nav:hover > svg path {
    fill-opacity: 1;
}

/* .arw-nav.actv {
    background-color: #5D5FEA;
    border-radius: 8px;
} */

.nav-bar ul li .arw-nav.actv + a {
    color: #ffffff;
} 

.nav-bar ul li .arw-nav.actv + a svg path {
    fill: #ffffff;
    fill-opacity: 1;
} 

/* .arw-nav:after {
    content: '';
    position: absolute;
    right: 16px;
    width: 10px;
    height: 48px;
    background: url(./assets/nav-arrow.svg) no-repeat center;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
} */

/* .arw-nav.actv:after {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
} */

.inner-custom-container {
    max-width: 1440px;
    margin: 0 auto;
}

@media only screen and (max-width:1199.98px) {
    body{
        padding-top: 65px;
    }
}
@media only screen and (max-width: 599px) {
    .custom-form input[type="password"] {
        font-size: 12px;
    }

    .custom-form input[type="password"]::placeholder {
        font-size: 16px;
    }
}
</style>
