import { createApp } from 'vue'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import App from './App.vue'
import router from './router'
import mitt from 'mitt';
const eventBus = mitt();

export { eventBus };

createApp(App).use(router).mount('#app')