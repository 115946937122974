<template>
    <div class="others-content-wrap">
        <div class="others-custom-container">
            <h2>Recover password</h2>
            <p>Create a new, strong password that you don’t use for other websites.</p>
            <div class="recover-password-success-box">
                <form @submit.prevent="submit">
                    <div class="custom-form">
                        <label>New password</label>
                        <div class="position-relative with_eye">
                            <input type="password" class="form-control password" :class="{'error': showPasswordError}" v-model="credentials.password" placeholder="Password" />
                            <div class="input-icon">
                                <img class="invisible_eye" src="../assets/EyeInvisibleOutlined.svg" alt="eye icon">
                                <img class="visible_eye" src="../assets/EyeOutlined.svg" alt="eye icon">
                            </div>
                        </div>
                        <p class="error_msg" v-if="showPasswordError">{{ passwordError }}</p>
                    </div>
                    <div class="custom-form">
                        <label>Repeat new password</label>
                        <div class="position-relative with_eye">
                            <input type="password" class="form-control password" :class="{'error': showConfirmError}" v-model="credentials.passwordConfirm" placeholder="Password" />
                            <div class="input-icon">
                                <img class="invisible_eye" src="../assets/EyeInvisibleOutlined.svg" alt="eye icon">
                                <img class="visible_eye" src="../assets/EyeOutlined.svg" alt="eye icon">
                            </div>
                        </div>
                        <p class="error_msg" v-if="showConfirmError">{{ confirmError }}</p>
                    </div>
                    <input type="submit" class="btn-default-violet full-btn" value="Log in with new password">
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import auth from '../auth'
export default {
    name: "recoverPasswordSuccess",
    data() {
        return {
            credentials: {
                password: '',
                passwordConfirm:''
            },
            passwordError: "Please enter your password.",
            showPasswordError: false,
            confirmError: "Please confirm your password.",
            showConfirmError: false,
            token: this.$route.params.token
        }
    },
    mounted() {
        // /////////////////////////////////////// Password eye hide show Start
        $(".input-icon").click(function () {
        $(this).toggleClass('active');
        if ($(this).hasClass("active")) {
            $(this).parents(".custom-form").find(".password").attr("type", "text");
        }else{
            $(this).parents(".custom-form").find(".password").attr("type", "password");
        }
        });
        // /////////////////////////////////////// Password eye hide show End
    },
    methods: {
        submit () {
            if(!this.credentials.password) {
                this.showPasswordError = true
                setTimeout(() => {
                    this.showPasswordError = false
                }, 2500);
                return;
            }
            if(!this.credentials.passwordConfirm) {
                this.showConfirmError = true
                setTimeout(() => {
                    this.showConfirmError = false
                }, 2500);
                return;
            }
            if(this.credentials.password != this.credentials.passwordConfirm) {
                this.showConfirmError = true
                this.showPasswordError = true
                this.confirmError = "Passwords do not match."
                this.passwordError = "Passwords do not match."
                setTimeout(() => {
                    this.showConfirmError = false
                    this.showPasswordError = false
                    this.passwordError = "Please enter your password."
                    this.confirmError = "Please confirm your password."
                }, 2500);
                return;
            }
            if(this.credentials.password.length < 8) {
                this.showConfirmError = true
                this.showPasswordError = true
                this.confirmError = "Password must be at least 8 characters long."
                this.passwordError = "Password must be at least 8 characters long."
                setTimeout(() => {
                    this.showConfirmError = false
                    this.showPasswordError = false
                    this.passwordError = "Please enter your password."
                    this.confirmError = "Please confirm your password."
                }, 2500);
                return;
            }
            var credentials = {
                password: this.credentials.password,
                passwordConfirm: this.credentials.passwordConfirm,
                resetToken: this.token
            }
            auth.resetPassword(this, credentials, '/gpucloud')
        }
    }
}
</script>

<style scoped>
.others-content-wrap {
    min-height: 100%;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    padding: 12px 0;
}

.others-custom-container {
    max-width: 460px;
    margin: 0 auto;
    width: 100%;
    text-align: center;
}

.others-content-wrap h2 {
    color: var(--result-color-text-heading, rgba(0, 0, 0, 0.88));
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 8px;
}

.others-content-wrap p {
    color: var(--result-color-text-description, rgba(0, 0, 0, 0.45));
    line-height: 22px;
}

.recover-password-success-box {
    margin-top: 24px;
}

.others-content-wrap .btn-default-violet {
    margin-top: 24px;
}
</style>