<template>
  <div class="inner-custom-container">
    <div class="row" v-if="instances">
      <div class="col-xl-4 col-sm-6 gpu-col" v-for="item in instances" :key="item.id">
        <div class="gpu-cloud-box">
          <div class="gpu-cloud-head">
            <div class="gpu-head-left">{{ item.gpuType }}</div>
            <div class="gpu-head-right">{{ item.gpuManufacture }}</div>
          </div>
          <div class="gpu-cloud-body">
            <!-- gpu repeat content start -->
            <div class="gpu-top-repeat">
              <div class="gpu-body-left">vRAM</div>
              <div class="gpu-body-right">{{ item.vRam }}Gb</div>
            </div>
            <div class="gpu-top-repeat">
              <div class="gpu-body-left">RAM</div>
              <div class="gpu-body-right">{{ item.ram }}Gb</div>
            </div>
            <div class="gpu-top-repeat">
              <div class="gpu-body-left">VCPU</div>
              <div class="gpu-body-right">{{ item.vCpu }}</div>
            </div>
            <!-- gpu repeat content end -->
            <!-- gpu dropdown content start -->
            <div class="gpu-dorpdown-conent">
              <!-- gpu dropdown box repeat start-->
              <div class="gpu-dorpdown-box">
                <div class="gpu-dropdown-main">
                  <div
                    class="gpu-dropdown-select"
                    style="color: rgba(0, 0, 0, 0.45)"
                  >
                    GPUs for rent
                    <div
                      class="gpu-dropdown-change d-inline-block float-end"
                      style="color: rgba(0, 0, 0, 0.88)"
                    >
                      <span style="color: rgba(0, 0, 0, 0.88)">1</span>
                    </div>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="Icon / DownOutlined">
                        <path
                          id="Vector"
                          d="M13.8125 4H12.6406C12.561 4 12.486 4.03906 12.4391 4.10313L8.00002 10.2219L3.56096 4.10313C3.51408 4.03906 3.43909 4 3.3594 4H2.18752C2.08596 4 2.02658 4.11563 2.08596 4.19844L7.59534 11.7937C7.79534 12.0687 8.20471 12.0687 8.40315 11.7937L13.9125 4.19844C13.9735 4.11563 13.9141 4 13.8125 4V4Z"
                          fill="black"
                          fill-opacity="0.88"
                        />
                      </g>
                    </svg>
                  </div>
                  <div class="gpu-dropdown-slide">
                    <ul>
                      <li v-for="(count, index) in counts" :key="index" @click="selectCount(count, instances.indexOf(item))" :class="{ active: index === selectedCountArray[instances.indexOf(item)] - 1 }">
                        {{ count }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="gpu-dorpdown-box">
                <div class="gpu-dropdown-main">
                  <div class="gpu-dropdown-select">
                    <div class="gpu-dropdown-change">
                      1 hr
                      <span style="color: rgba(0, 0, 0, 0.88)">$2.48/hr</span>
                    </div>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="Icon / DownOutlined">
                        <path
                          id="Vector"
                          d="M13.8125 4H12.6406C12.561 4 12.486 4.03906 12.4391 4.10313L8.00002 10.2219L3.56096 4.10313C3.51408 4.03906 3.43909 4 3.3594 4H2.18752C2.08596 4 2.02658 4.11563 2.08596 4.19844L7.59534 11.7937C7.79534 12.0687 8.20471 12.0687 8.40315 11.7937L13.9125 4.19844C13.9735 4.11563 13.9141 4 13.8125 4V4Z"
                          fill="black"
                          fill-opacity="0.88"
                        />
                      </g>
                    </svg>
                  </div>
                  <div class="gpu-dropdown-slide">
                    <ul>
                      <li @click="selectPlan(instances.indexOf(item), 2.48)" :class="{ active: priceArray[instances.indexOf(item)] === 2.48 }"><span>1 hr</span><span>$2.48/hr</span></li>
                      <li @click="selectPlan(instances.indexOf(item), 54.24)" :class="{ active: priceArray[instances.indexOf(item)] === 54.24 }">
                        <span>1 day</span><span>$2.26/hr</span>
                      </li>
                      <li @click="selectPlan(instances.indexOf(item), 1555.2)" :class="{ active: priceArray[instances.indexOf(item)] === 1555.2 }"><span>1 month</span><span>$2.16/hr</span></li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- gpu dropdown box repeat end-->
              <div v-if="!isLoading[instances.indexOf(item)]" v-on:click="handleDeploy(instances.indexOf(item))">
                <RouterLink to="" class="btn-default-violet full-btn">Deploy</RouterLink>
              </div>
              <div v-if="isLoading[instances.indexOf(item)]" class="btn-default-violet loading full-btn">
                <VueSpinner size="20" color="white" />
              </div>
            </div>
            <!-- gpu dropdown content end -->
          </div>
        </div>
      </div>
      <!-- ================================== Quan This one is the Out of stock Card ==================================== -->
      <div class="col-xl-4 col-sm-6 gpu-col" v-for="item in outInstances" :key="item.id">
        <div class="gpu-cloud-box">
          <div class="gpu-cloud-head">
            <div class="gpu-head-left">{{ item.gpuType }}</div>
            <div class="gpu-head-right">{{ item.gpuManufacture }}</div>
          </div>
          <div class="gpu-cloud-body">
            <!-- gpu repeat content start -->
            <div class="gpu-top-repeat">
              <div class="gpu-body-left">vRAM</div>
              <div class="gpu-body-right">{{ item.vRam }}Gb</div>
            </div>
            <div class="gpu-top-repeat">
              <div class="gpu-body-left">RAM</div>
              <div class="gpu-body-right">{{ item.ram }}Gb</div>
            </div>
            <div class="gpu-top-repeat">
              <div class="gpu-body-left">VCPU</div>
              <div class="gpu-body-right">{{ item.vCpu }}</div>
            </div>
            <!-- gpu repeat content end -->
            <!-- gpu dropdown content start -->
            <div class="gpu-dorpdown-conent">
              <!-- gpu dropdown box repeat start-->
              <div class="gpu-dorpdown-box">
                <div class="gpu-dropdown-main">
                  <div
                    class="gpu-dropdown-select"
                    style="color: rgba(0, 0, 0, 0.45)"
                  >
                    GPUs for rent
                    <div
                      class="gpu-dropdown-change d-inline-block float-end"
                      style="color: rgba(0, 0, 0, 0.88)"
                    >
                      <span style="color: rgba(0, 0, 0, 0.88)">1</span>
                    </div>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="Icon / DownOutlined">
                        <path
                          id="Vector"
                          d="M13.8125 4H12.6406C12.561 4 12.486 4.03906 12.4391 4.10313L8.00002 10.2219L3.56096 4.10313C3.51408 4.03906 3.43909 4 3.3594 4H2.18752C2.08596 4 2.02658 4.11563 2.08596 4.19844L7.59534 11.7937C7.79534 12.0687 8.20471 12.0687 8.40315 11.7937L13.9125 4.19844C13.9735 4.11563 13.9141 4 13.8125 4V4Z"
                          fill="black"
                          fill-opacity="0.88"
                        />
                      </g>
                    </svg>
                  </div>
                  <div class="gpu-dropdown-slide">
                    <ul>
                      <li v-for="(count, index) in counts" :key="index" @click="selectCount(count, instances.indexOf(item))" :class="{ active: index === selectedCountArray[instances.indexOf(item)] - 1 }">
                        {{ count }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="gpu-dorpdown-box">
                <div class="gpu-dropdown-main">
                  <div class="gpu-dropdown-select">
                    <div class="gpu-dropdown-change">
                      1 hr
                      <span style="color: rgba(0, 0, 0, 0.88)">$2.48/hr</span>
                    </div>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="Icon / DownOutlined">
                        <path
                          id="Vector"
                          d="M13.8125 4H12.6406C12.561 4 12.486 4.03906 12.4391 4.10313L8.00002 10.2219L3.56096 4.10313C3.51408 4.03906 3.43909 4 3.3594 4H2.18752C2.08596 4 2.02658 4.11563 2.08596 4.19844L7.59534 11.7937C7.79534 12.0687 8.20471 12.0687 8.40315 11.7937L13.9125 4.19844C13.9735 4.11563 13.9141 4 13.8125 4V4Z"
                          fill="black"
                          fill-opacity="0.88"
                        />
                      </g>
                    </svg>
                  </div>
                  <div class="gpu-dropdown-slide">
                    <ul>
                      <li @click="selectPlan(instances.indexOf(item), 2.48)" :class="{ active: priceArray[instances.indexOf(item)] === 2.48 }"><span>1 hr</span><span>$2.48/hr</span></li>
                      <li @click="selectPlan(instances.indexOf(item), 54.24)" :class="{ active: priceArray[instances.indexOf(item)] === 54.24 }">
                        <span>1 day</span><span>$2.26/hr</span>
                      </li>
                      <li @click="selectPlan(instances.indexOf(item), 1555.2)" :class="{ active: priceArray[instances.indexOf(item)] === 1555.2 }"><span>1 month</span><span>$2.16/hr</span></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="my-instances-box out_of_stock_state">
                <span>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 0.5C5.20156 0.5 0.5 5.20156 0.5 11C0.5 16.7984 5.20156 21.5 11 21.5C16.7984 21.5 21.5 16.7984 21.5 11C21.5 5.20156 16.7984 0.5 11 0.5ZM15.1367 14.2273L14.4664 15.1414C14.4518 15.1613 14.4335 15.1781 14.4124 15.1909C14.3913 15.2037 14.3678 15.2122 14.3434 15.2159C14.319 15.2196 14.2942 15.2184 14.2702 15.2125C14.2463 15.2065 14.2237 15.1959 14.2039 15.1813L10.3273 12.3547C10.3032 12.3373 10.2836 12.3145 10.2701 12.2879C10.2566 12.2614 10.2497 12.2321 10.25 12.2023V5.75C10.25 5.64687 10.3344 5.5625 10.4375 5.5625H11.5648C11.668 5.5625 11.7523 5.64687 11.7523 5.75V11.5508L15.0945 13.9672C15.1789 14.0258 15.1977 14.143 15.1367 14.2273Z"
                      fill="#FAAD14"
                    />
                  </svg>
                </span>
                <div class="my-instances-text">
                  <h4>Out of stock</h4>
                  <p>This GPU will be available soon. <br /><strong>Get in line!</strong></p>
                </div>
              </div>
              <!-- gpu dropdown box repeat end-->
              <RouterLink to="" class="btn-default-violet full-btn join-and-cancel-btn">Join waitlist</RouterLink>
            </div>
            <!-- gpu dropdown content end -->
          </div>
        </div>
      </div>
      <!-- ================================== This one is the Out of stock Card ==================================== -->
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { VueSpinner } from 'vue3-spinners';
import { eventBus } from '@/main'
import router from "@/router";
export default {
  name: "GpucloudPage",
  data() {
    return {
      instances: [
        {ID:"1212312", gpuType: "H100", gpuManufacture: "Nvidia", vRam: 32, ram: 16, vCpu: 8, count: 200},
        {ID:"1212312", gpuType: "A100", gpuManufacture: "Nvidia", vRam: 32, ram: 32, vCpu: 16, count: 100},
        {ID:"1212312", gpuType: "RTX6000 ADA", gpuManufacture: "Nvidia", vRam: 64, ram: 80, vCpu: 64, count: 300},
        {ID:"1212312", gpuType: "RTX4090", gpuManufacture: "Nvidia", vRam: 128, ram: 256, vCpu: 64, count: 100},
        {ID:"1212312", gpuType: "MI210", gpuManufacture: "AMD", vRam: 164, ram: 256, vCpu: 128, count: 200},
        {ID:"1212312", gpuType: "MI50", gpuManufacture: "AMD", vRam: 16, ram: 64, vCpu: 32, count: 500},
        {ID:"1212312", gpuType: "MI300x", gpuManufacture: "AMD", vRam: 16, ram: 32, vCpu: 8, count: 500},
      ],
      outInstances: [
        {ID:"1212312", gpuType: "H100", gpuManufacture: "Nvidia", vRam: 32, ram: 16, vCpu: 8, count: 0},
        {ID:"1212312", gpuType: "A100", gpuManufacture: "Nvidia", vRam: 32, ram: 32, vCpu: 16, count: 0},
        {ID:"1212312", gpuType: "RTX6000 ADA", gpuManufacture: "Nvidia", vRam: 64, ram: 80, vCpu: 64, count: 0},
        {ID:"1212312", gpuType: "RTX4090", gpuManufacture: "Nvidia", vRam: 128, ram: 256, vCpu: 64, count: 0},
        {ID:"1212312", gpuType: "MI210", gpuManufacture: "AMD", vRam: 164, ram: 256, vCpu: 128, count: 0},
        {ID:"1212312", gpuType: "MI50", gpuManufacture: "AMD", vRam: 16, ram: 64, vCpu: 32, count: 0},
        {ID:"1212312", gpuType: "MI300x", gpuManufacture: "AMD", vRam: 16, ram: 32, vCpu: 8, count: 0},
      ],
      counts: [1, 2, 3, 4, 5, 6, 7, 8],
      selectedCountArray: [1, 1, 1, 1, 1, 1, 1],
      priceArray: [2.48, 2.48, 2.48, 2.48, 2.48, 2.48, 2.48],
      total: null,
      loggedEmail: "",
      isLoading: [],
    }
  },
  methods: {
    fetchInstances() {
      fetch('https://slyd-fe5cef1c6ee5.herokuapp.com/api/instances/gpu')
        .then((response) => response.json())
        .then((json) => {
          this.instances = json?.data?.instances;
          this.outInstances = json?.data?.outInstances;
        })
        .catch((error) => {
          console.log('Error Fetching Instances:', error);
        });
    },
    selectCount(count, index) {
      this.selectedCountArray[index] = count;
    },
    selectPlan(index, price) {
      this.priceArray[index] = price;
    },
    handleDeploy(index) {
      this.isLoading[index] = true;
      if(!this.loggedEmail) {
        this.$router.push('/')
        return
      }

      var count = 1
      var plan = 2.48
      if (this.selectedCountArray[index]) {
        count = this.selectedCountArray[index]
        if (this.priceArray[index]) {
          plan = this.priceArray[index]
          this.total = this.selectedCountArray[index] * this.priceArray[index]
        } else {
          this.total = this.selectedCountArray[index] * 2.48
        }
      } else {
        if (this.priceArray[index]) {
          plan = this.priceArray[index]
          this.total = this.priceArray[index]
        } else {
          this.total = 2.48
        }
      }

      const idToken = localStorage.getItem('id_token')
      fetch('https://slyd-fe5cef1c6ee5.herokuapp.com/api/instances/create-checkout-url',{
        method: "POST",
        headers: {
          "Authorization": 'Bearer ' + idToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          amount: this.total,
          metadata: {count, plan, instanceId: this.instances[index].ID}
        })
      })
        .then((response) => response.json())
        .then((json) => {
          console.log("JSON:", json.checkout_url)
          window.location.href = json.checkout_url
          this.isLoading[index] = true
        })
        .catch((error) => {
          console.log('Error:', error);
        });
    }
  },
  components: {
    VueSpinner
  },
  mounted() {
    eventBus.emit('current-route', 'gpucloud')
    this.fetchInstances();
    const token = this.$route.query.token
    if(token) {
      localStorage.setItem('id_token', token)
      this.$router.push("/gpucloud").then(() => {
        window.location.reload();
      })
    }

    const idToken = localStorage.getItem('id_token')
    var loggedEmail = ''
    fetch('https://slyd-fe5cef1c6ee5.herokuapp.com/api/users/me', {
      headers: {
        "Authorization": 'Bearer ' + idToken
      }
    })
    .then((response) => response.json())
    .then((json) => {
      loggedEmail = json?.data?.user?.email
      this.loggedEmail = json?.data?.user?.email
    })
    .catch((error) => {
      console.log('Error Fetching User Data:', error);
    });

    // ///////////////////////////// dropdown start
    $(".gpu-dropdown-main").click(function (e) {
      e.preventDefault();
      $(this).find(".gpu-dropdown-slide").slideToggle(300);
      $(this).find(".gpu-dropdown-select").toggleClass("arw-roated");
      $(this)
        .parent(".gpu-dorpdown-box")
        .siblings()
        .find(".gpu-dropdown-slide")
        .slideUp(100);
      $(this)
        .parent(".gpu-dorpdown-box")
        .siblings()
        .find(".gpu-dropdown-select")
        .removeClass("arw-roated");
      $(this)
        .parents(".gpu-col")
        .siblings()
        .find(".gpu-dropdown-slide")
        .slideUp(100);
      $(this)
        .parents(".gpu-col")
        .siblings()
        .find(".gpu-dropdown-select")
        .removeClass("arw-roated");
    });

    //SELECT OPTIONS AND HIDE OPTION AFTER SELECTION
    $(".gpu-dropdown-slide ul li").click(function (e) {
      e.preventDefault();
      var text = $(this).html();
      $(this)
        .parents(".gpu-dorpdown-box")
        .find(".gpu-dropdown-change")
        .html(text);
      $(".gpu-dropdown-select").find(".gpu-dropdown-slide").slideUp(100);
    });

    // ///////////////////////////// dropdown end

    $(".gpu-dorpdown-box").click(function () {
      var scrollable_area = $(".custom-content-wrap").offset().top - 150;
      var dropdown = $(this);
      var dropdownContent = $(this).find(".gpu-dropdown-slide");
      var windowBottom = $(".custom-content-wrap").height();
      var dropdownBottom =
        dropdown.offset().top - scrollable_area + dropdown.outerHeight();
      console.log(dropdownBottom);
      // Check if dropdown is close to the bottom of the window
      if (dropdownBottom > windowBottom) {
        // If close to the bottom, display dropdown upwards
        dropdownContent.css({
          top: "auto",
          bottom: dropdown.outerHeight() + "px",
        });
      } else {
        // If not close to the bottom, display dropdown downwards
        dropdownContent.css({
          top: dropdown.outerHeight() + "px",
          bottom: "auto",
        });
      }
    });

    // =========================== Quan This is Join & Cancel waitlisting button functionality =====================
    $(".join-and-cancel-btn").click(function (e) {
      
      if(!loggedEmail) {
        router.push('/')
        return
      }
      e.preventDefault();
      var txt = $(this).text();
      var gpuModel = $(this).parents('.gpu-col').find('.gpu-cloud-head').find('.gpu-head-left').text();
      var gpuProcesser = $(this).parents('.gpu-col').find('.gpu-cloud-head').find('.gpu-head-right').text();
      if (txt == "Join waitlist") {
        $(this).text("Please wait..");

        // Quan, you need to dynamic the below static details with the logedin user details.
        var firstName = 'test';
        var lastName = 'last';
        var message = 'Joined Waitlist for '+gpuModel+' '+gpuProcesser; //This will remain static.

        fetch("https://dev.cloud.slyd.com/join-waitlist.php", {
          method: "POST",
          body: JSON.stringify({
            email: loggedEmail,
            firstName: firstName,
            lastName: lastName,
            message: message,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then((response) => response.json())
          .then((json) => {
            console.log(json.message);
            $(this).text("Cancel waitlist");
          });

      } else {
        $(this).text("Join waitlist");
      }
    });
    // ================================================================================================

  },
};
</script>
<style scoped>
.gpu-cloud-wrap {
  padding: 24px;
  background-color: #ffffff;
  margin: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.gpu-cloud-wrap {
  padding-top: 12px;
  padding-bottom: 12px;
}

.gpu-cloud-box {
  margin: 12px 0;
  border-radius: var(--card-border-radius-lg, 8px);
  border: 1px solid var(--card-color-border-secondary, rgba(0, 0, 0, 0.06));
  background: var(--card-color-bg-container, #fff);
}

.gpu-cloud-head {
  padding: 16px 24px;
  border-bottom: 1px solid
    var(--card-color-border-secondary, rgba(0, 0, 0, 0.06));
  background: var(--card-color-fill-alter, rgba(0, 0, 0, 0.02));
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.gpu-head-left {
  color: var(--card-color-text, rgba(0, 0, 0, 0.88));
  font-weight: 600;
  line-height: 22px;
}

.gpu-head-right {
  color: var(--colorTextDescription, rgba(0, 0, 0, 0.45));
  font-size: 12px;
  line-height: 20px;
}

.gpu-cloud-body {
  padding: 24px 24px;
}

.gpu-top-repeat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.gpu-top-repeat + .gpu-top-repeat {
  margin-top: 12px;
}

.gpu-body-left {
  color: var(--colorTextDescription, rgba(0, 0, 0, 0.45));
  line-height: 22px;
}

.gpu-body-right {
  color: var(--colorTextDescription, rgba(0, 0, 0, 0.88));
  line-height: 22px;
}

.gpu-dorpdown-conent {
  margin-top: 24px;
}

.gpu-dorpdown-box + .gpu-dorpdown-box {
  margin-top: 12px;
}

.gpu-dropdown-main {
  display: flex;
  min-height: 40px;
  padding: 0 38px 0 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--button-border-radius, 6px);
  border: 1px solid var(--button-color-border, rgba(0, 0, 0, 0.15));
  background: var(--button-color-bg-container, #fff);
  border-radius: 6px;
  position: relative;
  cursor: pointer;
}

.gpu-dorpdown-box + .gpu-dorpdown-box {
  margin-top: 12px;
}

.gpu-dropdown-select {
  width: 100%;
}

.gpu-dropdown-select svg {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.2s;
}

.gpu-dropdown-select.arw-roated svg {
  transform: translateY(-50%) rotate(180deg);
  transition: 0.2s;
}

.gpu-dropdown-change {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--colorTextDescription, rgba(0, 0, 0, 0.45));
  line-height: 22px;
}

.gpu-dropdown-change span:first-child {
  color: var(--colorTextDescription, rgba(0, 0, 0, 0.45));
}

.gpu-dropdown-change span {
  display: inline-block;
  color: var(--colorTextDescription, rgba(0, 0, 0, 0.88));
}

.gpu-dropdown-slide {
  position: absolute;
  width: 100%;
  overflow: hidden;
  right: 0;
  top: 45px;
  border-radius: var(--dropdown-border-radius-lg, 8px);
  background: var(--dropdown-color-bg-elevated, #fff);
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08);
  display: none;
  z-index: 9;
  /* border-bottom: 5px #5d5fea solid; */
}

.gpu-dropdown-slide ul {
  padding: 4px;
  margin: 0;
}

.gpu-dropdown-slide ul li {
  display: flex;
  padding: 6px 12px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: var(--button-color-text, rgba(0, 0, 0, 0.88));
  gap: 8px;
  border-radius: 4px;
}

.gpu-dropdown-slide ul li:hover {
  background: var(--dropdown-control-item-bg-hover, rgba(0, 0, 0, 0.04));
}

.gpu-dropdown-slide ul li.active {
  background: #efeffd;
  color: #5d5fea;
}

.gpu-dorpdown-conent .btn-default-violet {
  margin-top: 24px;
}

.gpu-dorpdown-conent .btn-default-violet {
  margin-top: 24px;
}

/*===================== Quan you need to copy New CSS Added here ========================*/

.my-instances-box {
  border-radius: var(--alert-border-radius-lg, 8px);
  border: 1px solid var(--alert-color-success-border, #ffe58f);
  background: var(--alert-color-success-bg, #fffbe6);
  padding: 20px 24px;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  margin-top: 24px;
  color: var(--alert-color-text, rgba(0, 0, 0, 0.88));
}

.my-instances-box h4 {
  color: var(--Alert-colorText, rgba(0, 0, 0, 0.88));
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  margin-bottom: 8px;
}

.my-instances-box p {
  color: var(--Alert-colorText, rgba(0, 0, 0, 0.88));
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  margin: 0;
}

/*===================== New CSS Added End ========================*/

@media only screen and (max-width: 599.98px) {
  .custom-content-wrap .inner-custom-container .row > * {
    width: 100%;
  }
}
</style>


