<template>
    <div class="others-content-wrap">
        <div class="others-custom-container">
            <figure class="email-icon">
                <svg width="64" height="50" viewBox="0 0 64 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M61.25 0.25H2.75C1.50547 0.25 0.5 1.25547 0.5 2.5V47.5C0.5 48.7445 1.50547 49.75 2.75 49.75H61.25C62.4945 49.75 63.5 48.7445 63.5 47.5V2.5C63.5 1.25547 62.4945 0.25 61.25 0.25ZM55.5688 7.90703L33.3852 25.1688C32.8367 25.5977 32.0703 25.5977 31.5219 25.1688L9.33125 7.90703C9.2476 7.84247 9.18624 7.75336 9.15578 7.65218C9.12533 7.551 9.12729 7.44283 9.1614 7.34282C9.19551 7.24281 9.26006 7.15599 9.346 7.09451C9.43194 7.03303 9.53496 6.99998 9.64062 7H55.2594C55.365 6.99998 55.4681 7.03303 55.554 7.09451C55.6399 7.15599 55.7045 7.24281 55.7386 7.34282C55.7727 7.44283 55.7747 7.551 55.7442 7.65218C55.7138 7.75336 55.6524 7.84247 55.5688 7.90703V7.90703Z"
                        fill="#5D5FEA" />
                </svg>
            </figure>
            <h2>Email confirmation</h2>
            <p>We have sent mail to <b>{{ credentials.email }}</b> to confirm the validity of our email address. After receiving the email follow the link provided to complete you registration.</p>
            <div class="resend-email">
                If you not got any mail <b v-on:click="resendEmail">Resend confirmation email</b>
                <VueSpinner class="m-3" v-if="isLoading" size="15" color="#5D5FEA" />
            </div>
            <p v-if="showError" class="error_msg">{{error}}</p>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import { VueSpinner } from 'vue3-spinners';
export default {
    name: "emailConfirmation",
    components: {
        VueSpinner
    },
    data() {
      return {
        credentials: {
            email: '',
            password: '',
            passwordConfirm: '',
        },
        showError: false,
        error: 'Please confirm your password.',
        isLoading: false,
      };
    },
    mounted() {
        const encodedEmail = this.$route.query.email;
        this.credentials.email = decodeURIComponent(encodedEmail);
        this.credentials.password = this.$route.query.password
        this.credentials.passwordConfirm = this.$route.query.passwordConfirm
    },
    methods: {
        resendEmail () {
            if (this.isLoading) {
                return null
            }
            var credentials = {
                email: this.credentials.email,
                password: this.credentials.password,
                passwordConfirm: this.credentials.passwordConfirm,
            }
            auth.resendEmail(this, credentials)
        }
    }
}
</script>

<style scoped>
.others-content-wrap {
    min-height: 100%;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    padding: 12px 0;
}

.others-custom-container {
    max-width: 460px;
    margin: 0 auto;
    width: 100%;
    text-align: center;
}

.email-icon {
    margin-bottom: 36px;
}

.others-content-wrap h2 {
    color: var(--result-color-text-heading, rgba(0, 0, 0, 0.88));
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 8px;
}

.others-content-wrap p {
    color: var(--result-color-text-description, rgba(0, 0, 0, 0.45));
    line-height: 22px;
}

.others-content-wrap p a {
    color: #5D5FEA;
    cursor: auto;
}

.others-content-wrap p a:hover {
    color: #7475E8;
}

.others-content-wrap p a:active {
    color: #4446E8;
}

.resend-email {
    color: var(--colorText, rgba(0, 0, 0, 0.88));
    line-height: 22px;
    margin-top: 24px;
}

.resend-email b {
    padding: 0 0 0 8px;
    color: #5D5FEA;
    white-space: nowrap;
    cursor: pointer;
}

.resend-email b:hover {
    color: #7475E8;
}

.resend-email b:active {
    color: #4446E8;
}
</style>