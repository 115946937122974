<template>
  <div class="login-content-wrap">
    <div class="login-box">
      <h1>Create your Account</h1>
      <div class="register-top-para">
        <p>Experience both speed and security by <br>joining the esteemed <br>SLYD community.</p>
      </div>
      <form @submit.prevent="submit">
        <div class="custom-form">
          <label>Email</label>
          <input class="form-control" :class="{'error': showEmailError}" v-model="credentials.email" placeholder="Email">
          <p class="error_msg" v-if="showEmailError">{{ emailError }}</p>
        </div>
        <div class="custom-form">
          <label>Password</label>
          <div class="position-relative with_eye">
            <input type="password" :class="{'error': showPasswordError}" v-model="credentials.password" class="form-control password" placeholder="Password" />
            <div class="input-icon">
              <img class="invisible_eye" src="../assets/EyeInvisibleOutlined.svg" alt="eye icon">
              <img class="visible_eye" src="../assets/EyeOutlined.svg" alt="eye icon">
            </div>
          </div>
          <p v-if="showPasswordError" class="error_msg">{{passwordError}}</p>
        </div>
        <div class="custom-form">
          <label>Confirm password</label>
          <div class="position-relative with_eye">
            <input type="password" :class="{'error': showError}" v-model="credentials.passwordConfirm" class="form-control password" placeholder="Password" />
            <div class="input-icon">
              <img class="invisible_eye" src="../assets/EyeInvisibleOutlined.svg" alt="eye icon">
              <img class="visible_eye" src="../assets/EyeOutlined.svg" alt="eye icon">
            </div>
          </div>
          <p v-if="showError" class="error_msg">{{error}}</p>
        </div>
        <div class="terms-condition-text">
          By Register you agree with <a href="https://www.google.com/" target="_blank">Terms of Use</a> & <a href="https://www.google.com/" target="_blank">Privacy Policy</a>
        </div>
        <input type="submit" v-if="!isLoading" value="Register" class="btn-default-violet full-btn">
        <div v-if="isLoading" class="btn-default-violet loading full-btn">
          <VueSpinner size="20" color="white" />
        </div>
        <p>Or <router-link to="/">Log in</router-link></p>
      </form>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import auth from '../auth'
import { RouterLink } from "vue-router";
import { VueSpinner } from 'vue3-spinners';
export default {
  name: "RegisterPage",
  data() {
    return {
      credentials: {
        email: '',
        password: '',
        passwordConfirm: ''
      },
      showEmailError: false,
      emailError: 'Please enter a valid email address.',
      showPasswordError: false,
      passwordError: 'Please enter your password.',
      showError: false,
      error: 'Please confirm your password.',
      isLoading: false,
    };
  },
  mounted() {
      // /////////////////////////////////////// Password eye hide show Start
      $(".input-icon").click(function () {
      $(this).toggleClass('active');
      if ($(this).hasClass("active")) {
          $(this).parents(".custom-form").find(".password").attr("type", "text");
      }else{
          $(this).parents(".custom-form").find(".password").attr("type", "password");
      }
      });
      // /////////////////////////////////////// Password eye hide show End
  },
  components: {
    RouterLink,
    VueSpinner
  },
  methods: {
    submit () {
      if (!this.credentials.email) {
        this.emailError = "Please enter your email."
        this.showEmailError = true;
        setTimeout(() => {
          this.emailError = "Please enter a valid email address."
          this.showEmailError = false;
        }, 2500);
        return;
      } else if (!this.isValidEmail) {
        this.showEmailError = true;
        setTimeout(() => {
          this.showEmailError = false;
        }, 2500);
        return;
      }
      if (!this.credentials.password) {
        this.showPasswordError = true;
        setTimeout(() => {
          this.showPasswordError = false;
        }, 2500);
        return;
      }
      if (!this.credentials.passwordConfirm) {
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
        }, 2500);
        return;
      }
      if (this.credentials.passwordConfirm != this.credentials.password) {
        this.error = "Passwords do not match."
        this.passwordError = "Passwords do not match."
        this.showError = true;
        this.showPasswordError = true;
        setTimeout(() => {
          this.showError = false;
          this.showPasswordError = false;
          this.error = "Please confirm your password.";
          this.passwordError = 'Please enter your password.';
        }, 2500);
        return;
      }

      var credentials = {
        email: this.credentials.email,
        password: this.credentials.password,
        passwordConfirm: this.credentials.passwordConfirm,
      }
      var errors = {
        email: this.emailError,
        password: this.passwordError,
        error: this.emailError
      }
      auth.signup(this, credentials, errors, '/emailconfirmation')
    }
  },
  computed: {
    isValidEmail() {
      return /^[^@]+@\w+(\.\w+)+\w$/.test(this.credentials.email);
    },
  }
}
</script>
<style scoped>
.login-content-wrap {
    min-height: 100%;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    padding: 12px 0;
}

.login-box {
    max-width: 460px;
    width: 100%;
    margin: 0 auto;
}

.login-box a {
    color: #5D5FEA;
}

.login-box a:hover {
    color: #7475E8;
}

.login-box a:active {
    color: #4446E8;
}

.login-box h1 {
    color: rgba(0, 0, 0, 0.88);
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    margin-bottom: 8px;
}

.register-top-para p {
    text-align: center;
    color: var(--result-color-text-description, rgba(0, 0, 0, 0.45));
    line-height: 22px;
    max-width: 435px;
    margin: 0 auto 24px;
}

.register-top-para p > br{
    display: none;
}

.forgot-password {
    text-align: right;
    line-height: 22px; 
}

.terms-condition-text {
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
    padding: 8px 0;
}

.terms-condition-text a {
    color: rgba(0, 0, 0, 0.88);
    padding: 8px 0;
}

.login-box .btn-default-violet {
    margin-top: 24px;
}

.login-box .btn-default-violet + p {
    text-align: center;
    margin: 5px 0 0 0;
    line-height: 24px;
}

.login-box .btn-default-violet + p a {
    padding: 0 0 0 8px;
}

@media only screen and (max-width:599.98px) {
  .register-top-para p {
    max-width: initial;
    margin: 0 0 24px;
}
.register-top-para p > br{
    display: block;
}
}
</style>