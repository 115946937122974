import axios from 'axios'
const API_URL = 'https://slyd-fe5cef1c6ee5.herokuapp.com/api/auth/'
const LOGIN_URL = API_URL + 'login'
const SIGNUP_URL = API_URL + 'register'
const RESEND_EMAIL = API_URL + 'resend-email'
const FORGOT_PASSWORD = API_URL + 'forgotpassword'
const RESET_PASSWORD = API_URL + 'resetpassword'

export default {
  login (context, creds, errors, redirect) {
    axios.post(LOGIN_URL, creds).then(response => {
      if (response?.data.status === 'success') {
        localStorage.setItem('id_token', response?.data?.token)
        if (redirect) {
          context.$router.push(redirect).then(() => {
            window.location.reload();
          });
        }
      } else {
        context.showPasswordError = true
        setTimeout(() => {
          context.passwordError = errors.password
          context.showPasswordError = false;
        }, 2500);
        context.passwordError = response?.data.message
      }
    }, error => {
      context.showPasswordError = true
      context.showEmailError = true
      context.passwordError = error.response?.data?.message
      context.emailError = error.response?.data?.message
      setTimeout(() => {
        context.emailError = errors.email
        context.passwordError = errors.password
        context.showEmailError = false;
        context.showPasswordError = false;
      }, 2500);
    })
  },

  signup (context, creds, errors, redirect) {
    context.isLoading = true;
    axios.post(SIGNUP_URL, creds).then(response => {
      if (response?.data.status === 'success') {
        context.isLoading = false;
        context.$router.push({
          path: redirect,
          query: {
            email: encodeURIComponent(creds.email),
            password: creds.password,
            passwordConfirm: creds.passwordConfirm
          }
        }).then(() => {
          window.location.reload();
        });
      } else {
        context.isLoading = false;
        context.error = response?.data.message
      }
    }, error => {
      context.isLoading = false;
      if (error.response?.data.message === "Key: 'SignUpInput.Password' Error:Field validation for 'Password' failed on the 'min' tag"){
        context.showPasswordError = true
        context.showError = true
        context.passwordError = "Password must contain at least 8 symbols."
        context.error = "Password must contain at least 8 symbols."
        setTimeout(() => {
          context.passwordError = errors.password
          context.error = errors.error
          context.showError = false;
          context.showPasswordError = false;
        }, 2500);
      } else if(error.response?.data.message === "User with that email already exists.") {
        context.showEmailError = true
        context.emailError = error.response?.data.message
        setTimeout(() => {
          context.emailError = errors.email
          context.showEmailError = false;
        }, 2500);
      }
    })
    .finally(() => {
      context.isLoading = false;
    });
  },
  
  resendEmail (context, creds) {
    context.isLoading = true;
    axios.post(RESEND_EMAIL, creds).then(response => {
        context.error = response?.data.message
    }, error => {
        context.showError = true
        context.error = error.response?.data.message
        setTimeout(() => {
          context.showError = false;
        }, 2500);
    })
    .finally(() => {
      context.isLoading = false;
    });
  },

  forgotPassword (context, creds, redirect) {
    context.isLoading = true;
    axios.post(FORGOT_PASSWORD, creds).then(response => {
      if (response?.data.status === 'success') {
        context.isLoading = false;
        context.$router.push({
          path: redirect,
          query: {
            email: encodeURIComponent(creds.email)
          }
        }).then(() => {
          window.location.reload();
        });
      } else {
        context.isLoading = false;
        context.emailError = response?.data.message
      }
    }, error => {
      context.showEmailError = true
      context.emailError = error.response?.data.message
      setTimeout(() => {
        context.showEmailError = false
      }, 2500)
    })
    .finally(() => {
      context.isLoading = false;
    });
  },


  resetPassword (context, creds, redirect) {
    context.isLoading = true;
    axios.patch(`${RESET_PASSWORD}/${creds.resetToken}`, creds).then(response => {
      if (response?.data?.status === "success") {
        localStorage.setItem('id_token', response?.data?.token)
        context.isLoading = false;
        context.$router.replace(redirect).then(() => {
          window.location.reload();
        });
      } else {
        context.showConfirmError = true
        context.showPasswordError = true
        context.confirmError = response?.data?.message
        context.passwordError = response?.data?.message
        setTimeout(() => {
          context.showConfirmError = false
          context.showPasswordError = false
          context.passwordError = "Please enter your password."
          context.confirmError = "Please confirm your password."
        }, 2500);
        return;
      }
    }, error => {
      context.showConfirmError = true
      context.showPasswordError = true
      context.confirmError = error?.response?.data?.message
      context.passwordError = error?.response?.data?.message
      setTimeout(() => {
        context.showConfirmError = false
        context.showPasswordError = false
        context.passwordError = "Please enter your password."
        context.confirmError = "Please confirm your password."
      }, 2500);
      return;
    })
    .finally(() => {
      context.isLoading = false;
    });
  },

  logout (context) {
    localStorage.removeItem('id_token')
    context.$router.replace('/')
  },

  isAuthenticated () {
    var jwt = localStorage.getItem('id_token')
    if (jwt) {
      return true
    }
    return false
  },

  getAuthHeader () {
    return {
      'Authorization': 'Bearer ' + localStorage.getItem('id_token')
    }
  }
}
